import Vue from 'vue';
import VueI18n from 'vue-i18n';

import ElementLocale from 'element-ui/lib/locale';
import {enabledLanguageMapping} from "~/utils/locale";

Vue.use(VueI18n);

export default ({ app, store }) => {
  ElementLocale.i18n((key, value) => app.i18n.t(key, value));
  // 资源加载完成，预加载全部国际化语言
  enabledLanguageMapping.forEach((item) => {
    setTimeout(async() => {
        const messages = await import(`~/locales/${item.file}`);
        app.i18n.mergeLocaleMessage(item.language, messages.default || messages)
    }, item.language === app.i18n.locale ? 0 : 3000)
  });
  app.i18n.path = link => {
    // if (app.i18n.locale === app.i18n.fallbackLocale) {
    //   return `/${link}`
    // }
    if(app.i18n.locale === 'en'){
      return `/${link}`;
    }else {
      let curLink = `/${app.i18n.locale}/${link}`
      // 去除路径末尾的斜杠
      if (curLink.endsWith('/')) {
        curLink = curLink.slice(0, -1);
      }
      return curLink;
    }
  };
};
