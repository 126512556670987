export default function ({isHMR, app, store, route, params, error, redirect}) {
  const defaultLocale = app.i18n.fallbackLocale
  // If middleware is called from hot module replacement, ignore it
  if (isHMR) return
  // Get locale from params
  let locale = params.lang;
  //404 页面 params.lang 获取的是空字符串
  if(!locale){
    locale=  store.state.locales.find((local)=>{
      return  new RegExp(`^\/${local}(?:\/|$)`).test(route.fullPath)
   }) || defaultLocale
  }
  if (!store.state.locales.includes(locale)) {
    return error({ message: 'This page could not be found.', statusCode: 404 })
  }
  // Set locale
  store.commit('SET_LANG', locale)
  app.i18n.setLocale(locale);
  app.i18n.locale = locale
}
